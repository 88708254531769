import {useTranslation} from "react-i18next";
import {StyledDivRoomNameInner, StyledDivRoomNamesHeader} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";

function MeetingRoomBookingMatrixRoomNamesHeaderComponent() {
    const {t} = useTranslation();
    return (
        <StyledDivRoomNamesHeader data-testid={"meeting-room-booking-matrix-room-names-header-test-id"}>
            <StyledDivRoomNameInner>
                {t("meeting-room-name")}
            </StyledDivRoomNameInner>
        </StyledDivRoomNamesHeader>
    );
}

export default MeetingRoomBookingMatrixRoomNamesHeaderComponent