import React, {useEffect, useRef, useState} from "react";
import {TimePicker} from "@mui/x-date-pickers";
import {getLeftPickerProps, getRightPickerProps} from "./timePickerConfig";
import {Dayjs} from "dayjs";


interface Props {
    startTime: Dayjs | null,
    onChangeStartTime: (newValue: Dayjs | null) => void,
    endTime: Dayjs | null,
    onChangeEndTime: (newValue: Dayjs | null) => void,
}

const DoubleTimePicker: React.FC<Props> = (props) => {
    const {startTime, endTime, onChangeStartTime, onChangeEndTime} = props;

    const [digitsEntered, setDigitsEntered] = useState(0);
    const leftPickerRef = useRef<HTMLInputElement | null>(null);
    const rightPickerRef = useRef<HTMLInputElement | null>(null);

    useEffect(function switchToSecondPicker() {
        if (digitsEntered < 4) {
            return;
        }
        rightPickerRef?.current?.focus();
    }, [digitsEntered]);

    function handleTextFieldSelect(event: any) {
        const element = event.target;
        if (element.selectionStart > 2) {
            // minutes selected
            setDigitsEntered(2);
        } else {
            // hours selected
            setDigitsEntered(0);
        }
    }

    function handleStartTimeChange(val: Dayjs | null) {
        onChangeStartTime(val);  // invalid or null Dayjs are handled/expected in BookingDialog
        if (val == null) {
            return;
        }
        if (!val.isValid()) {
            return; // minutes not entered
        }
        const minutesGEQSix = val.minute() >= 6; // 6 can't be firsts digit of minutes
        setDigitsEntered(prev => {
            if (prev >= 2 && minutesGEQSix) {
                return 4; // only when entering minute section
            }
            return prev + 1;
        });
    }

    const leftTimePickerProps= getLeftPickerProps(
        startTime,
        (newValue: Dayjs | null) => handleStartTimeChange(newValue),
        leftPickerRef,
        handleTextFieldSelect
    );

    const rightTimePickerProps= getRightPickerProps(
        endTime,
        (newValue: Dayjs | null) => onChangeEndTime(newValue),
        rightPickerRef,
    );

    return <>
        <TimePicker {...leftTimePickerProps}/>
        <TimePicker {...rightTimePickerProps}/>
    </>
}

export default DoubleTimePicker