import {gql, useMutation} from "@apollo/client";
import {deleteSecureSeatBooking} from "../../graphql/mutations";
import {Booking, DeleteBookingInput} from "../../API";
import {useCallback} from "react";

export function useDeleteSeatBooking() {

    const [deleteSeatBookingMutation] = useMutation(gql(deleteSecureSeatBooking));

    return useCallback((input: DeleteBookingInput | Booking) => {
        const params = {
            bookingId: input.bookingId,
            bookerId: input.bookerId,
            orgUnitId: input.orgUnitId,
            seatId: input.seatId,
            roomId: input.roomId,
            date: input.date
        }

        return deleteSeatBookingMutation({
            variables: {
                input: {
                    ...params
                }
            }
        });
    }, [deleteSeatBookingMutation]);
}