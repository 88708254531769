import {useEffect, useState} from 'react';
import {StoredSeatConfiguration, useMeetingRoomSeatConfigurations} from "./useMeetingRoomSeatConfigurations";
import {MeetingRoomBookingListItem, OwnMeetingRoomBooking} from '../types/MeetingRoomBookingListItemType';

const useSeatConfigs = (bookings: OwnMeetingRoomBooking[] | MeetingRoomBookingListItem[]) => {
    const [seatConfigs, setSeatConfigs] = useState<StoredSeatConfiguration[]>([]);
    const {getBatchSeatConfigurations} = useMeetingRoomSeatConfigurations();

    useEffect(() => {
        let tmpConfigMap: Map<string, string[]> = new Map<string, string[]>();
        bookings.forEach(item => {
            if (!tmpConfigMap.has(item.roomId)) {
                tmpConfigMap.set(item.roomId, []);
            }

            tmpConfigMap.get(item.roomId)?.push(item.meetingRoomId)
        })

        tmpConfigMap.forEach((value, key) => {
            getBatchSeatConfigurations(key, value)
                .then(c => {
                    setSeatConfigs(c)
                })
        })
    }, [bookings, getBatchSeatConfigurations]);

    return seatConfigs
};

export default useSeatConfigs;
