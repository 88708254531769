import {StyledDivRoomCapacitiesHeader} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import {Groups} from "@mui/icons-material";

const MeetingRoomBookingMatrixRoomCapacitiesHeaderComponent = () => (
    <StyledDivRoomCapacitiesHeader data-testid={"meeting-room-booking-matrix-room-capacities-header"}>
        <Groups/>
    </StyledDivRoomCapacitiesHeader>
);

export default MeetingRoomBookingMatrixRoomCapacitiesHeaderComponent
