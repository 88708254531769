import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Position} from '../SeatComponent'
import {useTranslation} from "react-i18next";

interface Props {
    bookerNamePosition: Position
    bookerFirstName: string | undefined
    bookerLastName: string | undefined
    bookerName: string | undefined
    seatOwner: string | undefined
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

const BookerName: React.FC<Props> = (props) => {
    const {t} = useTranslation();

    const bookerNamePosition = props.bookerNamePosition
    const bookerFirstName = props.bookerFirstName!.length > 10 ? props.bookerFirstName?.substring(0, 10).concat("...") : props.bookerFirstName
    const bookerLastName = props.bookerLastName!.length > 10 ? props.bookerLastName?.substring(0, 10).concat("...") : props.bookerLastName
    const seatOwner = props.seatOwner
    const bookerName = props.bookerName
    const setMouseOver = props.setMouseOver
    const [seatOwnerFirstName, setSeatOwnerFirstName] = useState("");
    const [seatOwnerLastName, setSeatOwnerLastName] = useState("")

    const onMouseEnter = () => {
        setMouseOver(true)
    }
    const onMouseLeave = () => {
        setMouseOver(false)
    }

    useEffect(() => {
        if (seatOwner) {
            const seatOwnerParts = seatOwner.split(" ");
            const lastName = seatOwnerParts.pop() || "";
            const firstName = seatOwnerParts.join(" ");

            setSeatOwnerLastName(lastName.length > 10 ? lastName.substring(0, 10).concat("...") : lastName);
            setSeatOwnerFirstName(firstName.length > 10 ? firstName.substring(0, 10).concat("...") : firstName);
        }
    }, [seatOwner]);

    const fontSize = props.bookerFirstName!.length > 10 || props.bookerLastName!.length > 10 || seatOwnerFirstName.length > 10 || seatOwnerLastName.length > 10 ? 5.64 : 8.64;

    return (
        <text
            className="BookerName"
            xmlSpace="preserve"
            fill="#000"
            strokeWidth=".778"
            fontFamily="sans-serif"
            fontSize={fontSize}
            fontWeight="400"
            letterSpacing="0"
            onMouseOver={onMouseEnter}
            onMouseOut={onMouseLeave}
            x={bookerNamePosition.x}
            y={bookerNamePosition.y}
            transform={bookerNamePosition.transform}>
            <tspan x={bookerNamePosition.x}
                   y={bookerNamePosition.y}>{
                seatOwner ? seatOwnerFirstName : bookerFirstName !== undefined && bookerFirstName.length > 0 ? bookerFirstName
                    : bookerLastName !== undefined && bookerLastName.length === 0 && bookerName !== undefined ? t("anonymous") : ""}</tspan>
            <tspan x={bookerNamePosition.x}
                   y={parseFloat(bookerNamePosition.y as string) + 7}>{seatOwner ? seatOwnerLastName : bookerLastName !== undefined ? bookerLastName : ""}</tspan>
        </text>
    )
        ;
}

export default BookerName;