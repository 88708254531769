import {ApolloQueryResult, gql, useQuery} from "@apollo/client";
import {getMeetingRoomBookings} from "../graphql/queries";
import {MeetingRoomBooking} from "../API";
import {createNewTodayDateWithoutHours} from "../services/DateUtils";
import {useMainApplicationContext} from "./useMainApplicationContext";

const EMPTY_ARRAY: MeetingRoomBooking[] = [];
const today: Date = createNewTodayDateWithoutHours()

export function useGetOwnMeetingRoomBookingsByBooker(): [MeetingRoomBooking[], () => Promise<ApolloQueryResult<any>>] {
    const {currentUser} = useMainApplicationContext();
    const {data, refetch} = useQuery(gql(getMeetingRoomBookings), {
        variables: {
            bookerId: currentUser.ID,
            date: today,
            limit: 999
        },
    });
    return [data?.getMeetingRoomBookings.items ?? EMPTY_ARRAY, refetch];
}