import React from "react";
import MeetingRoomBookingMatrixRoomNameComponent, {RoomProps} from "./MeetingRoomBookingMatrixRoomNameComponent";
import {MeetingRoomEquipment} from "../../../API";

interface Props {
    sortedRooms: RoomProps[],
    buildingEquipment: Map<string, MeetingRoomEquipment>,
}

function MeetingRoomBookingMatrixRoomNamesComponent(props: Props) {
    return <>
        {
            props.sortedRooms.map((props, index) => {
                return <MeetingRoomBookingMatrixRoomNameComponent
                    key={props.data.meetingRoomId + `-${index}`} {...props} />
            })
        }
    </>
}

export default MeetingRoomBookingMatrixRoomNamesComponent