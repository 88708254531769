import {darken, styled} from "@mui/material";
import maternaTheme from "./materna_theme";

const defaultBorderColor = "#ccc";
const defaultBorder = `1px solid ${defaultBorderColor}`;
const matrixRowHeight = "30px";
const colorOtherBooking = maternaTheme.palette.primary.main;
const colorOtherBookingHover = maternaTheme.palette.primary.dark;

const colorOwnBooking = "#00F"; //todo which blue?
const colorOwnBookingHover = darken(colorOwnBooking, 0.5); //todo how dark?

const StyledDivMatrixContainer = styled("div")({
    display: "flex",
    overflow: "scroll"
});

const StyledDivMatrixTimeContainer = styled("div")({
    borderRight: defaultBorder,
    borderBottom: defaultBorder,
    backgroundImage: "linear-gradient(90deg, #ccc 1px, transparent 1px 100%), linear-gradient(#ccc 1px, transparent 1px 100%)"
});

const StyledDivLeftPanel = styled("div")({
    display: "flex",
    flexDirection: "column",
    maxWidth: "20%",
    boxSizing: "border-box",
    borderLeft: defaultBorder,
    borderTop: defaultBorder,
    borderBottom: defaultBorder,
    minWidth: "150px"
});

const StyledDivCapacityPanel = styled("div")({
    display: "flex",
    flexDirection: "column",
    maxWidth: "5%",
    boxSizing: "border-box",
    borderLeft: defaultBorder,
    borderTop: defaultBorder,
    borderBottom: defaultBorder,
    minWidth: "45px"
});

const StyledDivRightPanel = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
});

const StyledDivTimeHeader = styled("div")({
    display: "flex",
    borderRight: defaultBorder,
    borderTop: defaultBorder,
    boxSizing: "border-box"
});

const StyledDivRoomNameOuter = styled("div")({
    paddingLeft: "10px",
    paddingRight: "5px",
    height: matrixRowHeight,
    borderTop: defaultBorder,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
});

const StyledDivRoomCapacity = styled("div")({
    paddingLeft: "10px",
    paddingRight: "10px",
    height: matrixRowHeight,
    borderTop: defaultBorder,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "right"
});

const StyledDivRoomNameInner = styled("div")({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
});

const StyledDivRoomNameIconContainer = styled("div")({
    minWidth: "30px",
});


const StyledDivOwnBookingTimeSlotInner = styled("div")({
    overflow: "hidden",
    background: colorOwnBooking,
    margin: "4px 0px",
    borderRadius: "8px",
    flexGrow: 1,
    position: "relative",
    transition: "0.3s",
    "&:hover": {
        background: colorOwnBookingHover,
    }
});

const StyledDivOtherManageableBookingTimeSlotInner = styled("div")({
    overflow: "hidden",
    background: colorOtherBooking,
    margin: "4px 0px",
    borderRadius: "8px",
    flexGrow: 1,
    position: "relative",
    transition: "0.3s",
    "&:hover": {
        background: colorOtherBookingHover,
    }
});

const StyledDivOtherBookingTimeSlotInner = styled("div")({
    overflow: "hidden",
    background: colorOtherBooking,
    margin: "4px 0px",
    borderRadius: "8px",
    flexGrow: 1,
    position: "relative"
});

const StyledDivEmptyTimeslotInner = styled("div")({
    overflow: "hidden",
    margin: "4px 2px",
    borderRadius: "4px",
    flexGrow: 1,
    position: "relative",
    transition: "0.3s",
    "&:hover": {
        background: "rgba(200,245,210,0.5)",
        border: "1px solid black",
    },
    boxSizing: "border-box"
});

const StyledDivMatrixRow = styled("div")({
    display: "flex",
    height: matrixRowHeight
});

const StyledDivRoomNamesHeader = styled("div")({
    paddingLeft: "10px",
    paddingRight: "20px",
    height: matrixRowHeight,
    display: "flex",
    alignItems: "center",
});

const StyledDivRoomCapacitiesHeader = styled("div")({
    paddingLeft: "10px",
    paddingRight: "10px",
    height: matrixRowHeight,
    display: "flex",
    alignItems: "center",
});

const StyledDivHourDisplayOuter = styled("div")({
    height: matrixRowHeight,
    flexGrow: 1,
    borderLeft: defaultBorder,
    position: "relative",
    overflow: "hidden"
});

const StyledDivHourDisplayInner = styled("div")({
    fontSize: "x-small",
    position: "absolute",
    bottom: "1px",
    left: "2px"
});

const StyledDivTimeSlotOuter = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    minWidth: "28px"
});


export {
    StyledDivMatrixContainer, StyledDivMatrixTimeContainer, StyledDivLeftPanel, StyledDivCapacityPanel,
    StyledDivRightPanel, StyledDivRoomNameOuter, StyledDivRoomNameInner,
    StyledDivRoomNameIconContainer, StyledDivTimeSlotOuter, StyledDivOwnBookingTimeSlotInner,
    StyledDivOtherBookingTimeSlotInner, StyledDivOtherManageableBookingTimeSlotInner,
    StyledDivEmptyTimeslotInner, StyledDivMatrixRow, StyledDivTimeHeader,
    StyledDivRoomNamesHeader, StyledDivHourDisplayOuter, StyledDivHourDisplayInner,
    StyledDivRoomCapacitiesHeader, StyledDivRoomCapacity
};