import {gql, useMutation} from "@apollo/client";
import {deleteSecureMeetingRoomBooking} from "../../graphql/mutations";
import {useCallback} from "react";
import {DeleteMeetingRoomBookingInput, MeetingRoomBooking} from "../../API";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";

export function useDeleteMeetingRoomBooking(){
    const [deleteMeetingRoomBookingMutation] = useMutation(gql(deleteSecureMeetingRoomBooking));

    return useCallback((input: DeleteMeetingRoomBookingInput | MeetingRoomBooking | OwnMeetingRoomBooking) => {
        const params = {
            bookingId: input.bookingId,
            bookerId: input.bookerId,
            orgUnitId: input.orgUnitId,
            meetingRoomId: input.meetingRoomId,
            date: input.date,
            roomId: input.roomId
        }

        return deleteMeetingRoomBookingMutation({
            variables: {
                input: {
                    ...params
                }
            }
        });
    }, [deleteMeetingRoomBookingMutation]);
}