import {useGetOwnMeetingRoomBookingsByBooker} from "../useBookingsByBooker";
import {useCallback, useMemo, useState} from "react";
import {useMeetingRoomList} from "../useMeetingRoomList";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";
import {MeetingRoomType} from "../../Utils/Enums";
import {useDeleteMeetingRoomBooking} from "./useDeleteMeetingRoomBooking";
import {useErrorContext} from "../useErrorContext";
import {useTranslation} from "react-i18next";

// For OwnBookingsManagerComponent
// Fetch and delete meeting room bookings, store selection
export function useOwnDetailedMRBookings() {
    const {reportError} = useErrorContext();
    const [ownMRBookings, refetchMRBookings] = useGetOwnMeetingRoomBookingsByBooker();
    const roomIdsFromBookings = useMemo(() => {
        return Array.from(new Set(ownMRBookings.map(b => b.roomId)));
    }, [ownMRBookings]);
    const [meetingRooms, , areMeetingRoomsLoading] = useMeetingRoomList(roomIdsFromBookings);
    const [selectedMRBookings, setSelectedMRBookings] = useState<OwnMeetingRoomBooking[]>([]);
    const [isDeleteAndRefetchInProgress, setIsDeleteAndRefetchInProgress] = useState(false);
    const deleteMeetingRoomBooking = useDeleteMeetingRoomBooking();
    const  {t} = useTranslation();

    const detailedBookings = useMemo(((): OwnMeetingRoomBooking[] => {
        if (areMeetingRoomsLoading) {
            return [];
        }
        return ownMRBookings.map(booking => {
            const matchedMeetingRoom = meetingRooms.find((meetingRoom) => meetingRoom.meetingRoomId === booking.meetingRoomId);
            return {
                ...booking,
                meetingRoomName: matchedMeetingRoom?.name ?? "",
                meetingRoomType: matchedMeetingRoom?.type ?? MeetingRoomType.INTERNAL,
                managingAllowed: true
            }
        })
    }), [areMeetingRoomsLoading, ownMRBookings, meetingRooms]);

    const onSelectMRBookings = useCallback((newSelection: OwnMeetingRoomBooking[]) => {
        setSelectedMRBookings(newSelection);
    }, []);

    const refetchMRBookingsWithErrorHandling = useCallback(async () => {
        return refetchMRBookings()
            .catch(
                (err) => reportError(err, t("error_loading_bookings"), "useOwnDetailedMRBookings refetchMRBookingsWithErrorHandling")
            )
    }, [refetchMRBookings, reportError, t]);

    const deleteSelectedMeetingRoomBookings = useCallback(async () => {
        if (selectedMRBookings.length === 0) {
            return;
        }
        setIsDeleteAndRefetchInProgress(true);
        const deletionPromises = selectedMRBookings
            .map(mrBooking => deleteMeetingRoomBooking(mrBooking));

        onSelectMRBookings([]);
        return Promise.all(deletionPromises)
            .catch(
                (err) => reportError(err, t("error_deleting_bookings"), "useOwnDetailedMRBookings deleteSelectedMeetingRoomBookings"))
            .finally(() => {
                    refetchMRBookingsWithErrorHandling()
                        .finally(() => setIsDeleteAndRefetchInProgress(false));
                }
            );
    }, [deleteMeetingRoomBooking, refetchMRBookingsWithErrorHandling, onSelectMRBookings, reportError, selectedMRBookings, t])

    return {
        areMRBookingsloading: areMeetingRoomsLoading || isDeleteAndRefetchInProgress,
        detailedMRBookings: detailedBookings,
        selectedMRBookings: selectedMRBookings,
        onSelectMRBookings: onSelectMRBookings,
        refetchMRBookings: refetchMRBookingsWithErrorHandling,
        deleteSelectedMRBookingsAndRefetch: deleteSelectedMeetingRoomBookings,
    };
}