import React from "react";
import {useTranslation} from "react-i18next";
import {DialogTitle} from "@material-ui/core";
import {Building, Room} from "../../API";
import {BookingType} from "../../types/BookingType";
import {styled} from "@mui/material";

export interface MultiBookingDialogTitleProps {
    bookingType: BookingType,
    room: Room,
    seatId: string,
    building: Building | undefined,
}

const MultiBookingDialogTitle: React.FC<MultiBookingDialogTitleProps> = (props) => {
    const {
        bookingType,
        room,
        seatId,
        building,
    } = props
    const {t} = useTranslation();

    function showBookingDialogTitle(bookingType: BookingType) {
        const bookingTypeTranslation = bookingType === "meetingRoom" ? `${t("multibookingdialog_title_booking_type_meeting_room")}` : `${t("multibookingdialog_title_booking_type_seat")}`;
        const combinedTitle = `${t("multibookingdialog_title_normal", {type: bookingTypeTranslation})}: ${building?.buildingName}, ${room.name}, ${seatId}`;

        return combinedTitle;
    }

    return (
        <>
            <DialogTitle>
                {showBookingDialogTitle(bookingType)}
            </DialogTitle>
        </>
    )
}

export default MultiBookingDialogTitle
