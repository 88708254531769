import React from "react";
import {CircularProgress} from "@material-ui/core";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";
import {OwnMeetingRoomBookingsSortableTable} from "./OwnMeetingRoomBookingsSortableTable";

export interface OwnMeetingBookingsProps {
    showLoadingIndicator: boolean
    bookings: OwnMeetingRoomBooking[]
    selectedMRBookings: OwnMeetingRoomBooking[]
    onSelectMRBookings: (newSelection: OwnMeetingRoomBooking[]) => void
}

export const OwnMeetingBookingsTabComponent: React.FC<OwnMeetingBookingsProps> = (props) => {
    const {
        showLoadingIndicator,
        bookings,
        selectedMRBookings,
        onSelectMRBookings
    } = props;

    return (
            <>
                {showLoadingIndicator ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                        <CircularProgress/>
                    </div>
                    :
                    <OwnMeetingRoomBookingsSortableTable
                        bookings={bookings}
                        selectedMRBookings={selectedMRBookings}
                        onSelectMRBookings={onSelectMRBookings}
                    />
                }
            </>
    );
}