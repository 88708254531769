import React from "react";
import {StyledDivRoomCapacity} from "../../../styles/MeetingRoomBookingMatrixStyles";

interface Props {
    sortedRoomCapacities: Array<number | null>,
}

function MeetingRoomBookingMatrixRoomCapacitiesComponent({sortedRoomCapacities}: Props) {
    return <>
        {
            sortedRoomCapacities.map((capacity, index) => {
                return (
                    <StyledDivRoomCapacity key={index} data-testid={"meeting-room-booking-matrix-room-capacity"}>
                        <span>{capacity ?? 0}</span>
                    </StyledDivRoomCapacity>
                )
            })
        }
    </>
}

export default MeetingRoomBookingMatrixRoomCapacitiesComponent
