import {TimePickerProps} from "@mui/x-date-pickers/TimePicker/TimePicker.types";
import {Dayjs} from "dayjs";
import {renderTimeViewClock} from "@mui/x-date-pickers";
import {CustomTextFieldLeft, CustomTextFieldRight} from "../BookingDialog/CustomTextField";
import React from "react";


export function getLeftPickerProps(timeValue: Dayjs | null | undefined, onTimeChange: (newValue: Dayjs | null) => void, pickerRef: React.MutableRefObject<HTMLInputElement | null>, onTextFieldSelect: (event: any) => void) {
    return {
        ...baseTimePickerProps,
        value: timeValue,
        onChange: onTimeChange,
        slots: {textField: CustomTextFieldLeft},
        slotProps: {...skipClockButtonOnTabSlotProps,
            textField: {InputProps: {inputRef: pickerRef, onSelect: onTextFieldSelect}}
        }
    }
}

export function getRightPickerProps(timeValue: Dayjs | null | undefined, onTimeChange: (newValue: Dayjs | null) => void, pickerRef: React.MutableRefObject<HTMLInputElement | null>) {
    return {
        ...baseTimePickerProps,
        value: timeValue,
        onChange: onTimeChange,
        slots: {textField: CustomTextFieldRight},
        slotProps: {
            ...skipClockButtonOnTabSlotProps,
            textField: {InputProps: {inputRef: pickerRef}}
        }
    }
}

const skipClockButtonOnTabSlotProps = {
    openPickerButton: {
        tabIndex: -1
    }
}

const baseTimePickerProps: TimePickerProps<Dayjs> = {
    // show hours and minutes as clock
    viewRenderers: {hours: renderTimeViewClock, minutes: renderTimeViewClock},
}
