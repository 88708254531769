import {useErrorContext} from "../useErrorContext";
import {useCallback} from "react";


export function useHandleUnknownErrors() {
    const {reportError} = useErrorContext();

    return useCallback((error: any, displayMessage: string, source: string) => {
        if (error instanceof Error) {
            reportError(error, displayMessage, source);
        } else {
            console.error("An unknown error occurred:", error);
        }
    }, [reportError])
}