import {useMainApplicationContext} from "../useMainApplicationContext";
import {gql, useQuery} from "@apollo/client";
import {getSeatBookings} from "../../graphql/queries";
import {createNewTodayDateWithoutHours, toDateISO} from "../../services/DateUtils";
import {Booking} from "../../API";
import {useCallback} from "react";

export function useOwnCurrentSeatBookingsWithRoomNames() {
    const {rooms, currentUser} = useMainApplicationContext();
    const today: string = toDateISO(createNewTodayDateWithoutHours());
    const bookerId = currentUser.ID;
    const {
        refetch: refetchBookingsByBooker,
        loading: areBookingsLoading
    } = useQuery(gql(getSeatBookings), {
        variables: {
            input: {
                bookerId: bookerId,
                date: today,
                typeOfQuery: 'bookingsByBookerAndDate'
            },
            limit: 999
        }
    });

    const fetchSeatBookings = useCallback(async () => {
        const bookingsByBookerData = await refetchBookingsByBooker();
        return bookingsByBookerData.data.getSeatBookings.items.map((b: Booking) => ({
                    ...b,
                    roomName: rooms.find((room) => room.roomId === b.roomId)?.name,
                    __typename: "Booking"
                }));
    },[rooms, refetchBookingsByBooker]);

    return {areBookingsLoading: areBookingsLoading, fetchSeatBookings: fetchSeatBookings};
}