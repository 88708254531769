import {ButtonProps} from "@material-ui/core";
import React from "react";
import {PrimaryButton} from "./PrimaryButton";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";


export const DialogButton: React.FC<ButtonProps> = ({children, ...props}) => {
    const {shouldAlignButtons} = useDeviceMediaType();
    return (
        <PrimaryButton size={shouldAlignButtons ? "small" : "medium"} {...props}>
            {children}
        </PrimaryButton>
    );
}