import {useMemo} from "react";
import {useMainApplicationContext} from "../useMainApplicationContext";

export function useCheckForBookingsWithTime(bookings: {roomId: string}[]) {
    const {rooms} = useMainApplicationContext();

    return useMemo<boolean>(() => {
        return bookings.some((booking: {roomId: string}) => {
            const roomOfTheBooking = rooms.find((room) => room.roomId === booking.roomId);
            return roomOfTheBooking?.isTimeActive
        })
    },[bookings, rooms]);
}