import {useErrorContext} from "./useErrorContext";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    createMeetingRoomSeatConfig,
    deleteMeetingRoomSeatConfig,
    updateMeetingRoomSeatConfig
} from "../graphql/mutations";
import {listMeetingRoomSeatConfigs} from "../graphql/queries";
import {useCallback} from "react";
import {useFileUpload} from "./useFileUpload";
import {MeetingRoomSeatConfig} from "../API";

export type StoredSeatConfiguration = MeetingRoomSeatConfig & {
    imageUrl: string | null | undefined
}

export const useMeetingRoomSeatConfigurations = () => {
    const {reportError} = useErrorContext()
    const {getImageUrl} = useFileUpload()

    const [createSeatConfigMutation] = useMutation(gql(createMeetingRoomSeatConfig));
    const [updateSeatConfigMutation] = useMutation(gql(updateMeetingRoomSeatConfig));
    const [deleteSeatConfigMutation] = useMutation(gql(deleteMeetingRoomSeatConfig));

    const {data, refetch} = useQuery(gql(listMeetingRoomSeatConfigs))

    const createSeatConfig = async (input: any) => {
        await createSeatConfigMutation({variables: {input: input}})
            .catch((err) => reportError(err, "", "useMeetingRoomSeatConfigurations createSeatConfig"));
        refetch()
    }

    const updateSeatConfig = async (input: any) => {
        console.log("Update meetroom seatconf input: ", input)
        await updateSeatConfigMutation({variables: {input: input}})
            .catch((err) => reportError(err, "", "useMeetingRoomSeatConfigurations updateSeatConfig"));
        refetch()
    }

    const deleteSeatConfig = async (id: string) => {
        await deleteSeatConfigMutation({variables: {input: {meetingRoomSeatConfId: id}}})
            .catch((err) => reportError(err, "", "useMeetingRoomSeatConfigurations deleteSeatConfig"));
        refetch()
    }

    const getSeatConfigurations = useCallback(async (roomId: string, meetingRoomId?: string) => {
        return data
            ? Promise.all(data.listMeetingRoomSeatConfigs.items
                .filter((item: any) => item.roomId === roomId && (!meetingRoomId || item.meetingRoomId === meetingRoomId))
                .map(mapStoredSeatConfiguration))
            : []
    }, [data])

    const getBatchSeatConfigurations = useCallback(async (roomId: string, meetingRoomIds?: string[]) => {
        return data
            ? Promise.all(data.listMeetingRoomSeatConfigs.items
                .filter((item: any) => item.roomId === roomId && (!meetingRoomIds || meetingRoomIds.length === 0 || meetingRoomIds.includes(item.meetingRoomId)))
                .map(mapStoredSeatConfiguration))
            : []
    }, [data])

    const mapStoredSeatConfiguration = async (item: any): Promise<StoredSeatConfiguration> => {
        return {
            ...item,
            imageUrl: item.imageId ? await getImageUrl(item.roomId, item.imageId) : undefined
        }
    }

    return {createSeatConfig, updateSeatConfig, getSeatConfigurations, deleteSeatConfig, getBatchSeatConfigurations}
}