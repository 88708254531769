import React from "react";
import {Booking} from "../../API";
import {CircularProgress} from "@material-ui/core";
import {OwnSeatBookingsSortableTable} from "./OwnSeatBookingsSortableTable";
import {DetailedSeatBooking} from "../../types/ExtendedSeatBookings";

export interface OwnSeatBookingsProps {
    showLoadingIndicator: boolean
    bookings: DetailedSeatBooking[]
    selectedBookings: Booking[]
    onBookingsSelect: (newSelection: Booking[]) => void
}

export const OwnSeatBookingsTabComponent: React.FC<OwnSeatBookingsProps> = (props) => {
    const {
        showLoadingIndicator,
        bookings,
        selectedBookings,
        onBookingsSelect,
    } = props;

    return (
        <>
            {showLoadingIndicator ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress/>
                </div>
                :
                <OwnSeatBookingsSortableTable
                    bookings={bookings}
                    selectedBookings={selectedBookings}
                    onSelectBookings={onBookingsSelect}
                />
            }
        </>
    );
}
