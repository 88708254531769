import {Button, ButtonProps} from "@material-ui/core";
import React from "react";


export const PrimaryButton: React.FC<ButtonProps> = ({children, ...props}) => {
    return (
        <Button color={"primary"} variant={"contained"} {...props}>
            {children}
        </Button>
    );
}