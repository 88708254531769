import React from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {MeetingRoom} from "../../API";
import {SeatAndNeighborhood} from "../../Utils/Helpers";

interface Props {
    showUploadConfirmationDialog: boolean,
    seatIdsToDelete: SeatAndNeighborhood[],
    seatsToUpdate: SeatAndNeighborhood[],
    meetingRoomIdsToDelete: string[],
    meetingRoomsToUpdate: MeetingRoom[]
    onUploadLoading: boolean
    onCloseUploadConfirmationDialog: () => void
    triggerUploadRoomPlan: () => void
}


const RoomManagerUploadConfirmationDialog: React.FC<Props> = (props) => {
    const {
        showUploadConfirmationDialog,
        seatIdsToDelete,
        meetingRoomIdsToDelete,
        onUploadLoading,
        onCloseUploadConfirmationDialog,
        triggerUploadRoomPlan,
        meetingRoomsToUpdate,
        seatsToUpdate
    } = props

    const {t} = useTranslation();

    return (
        <Dialog open={showUploadConfirmationDialog}>
            <DialogContent>
                {meetingRoomIdsToDelete.length > 0 ? (
                    <>
                        <p>{t("rm_showUploadConfirmationDialog-text-meetingRooms")}</p>
                        {meetingRoomIdsToDelete.map((meetingRoomId) => (
                            <p key={meetingRoomId}><strong>{meetingRoomId}</strong></p>
                        ))}
                        <br></br>
                    </>
                ) : null}
                {meetingRoomsToUpdate.length > 0 ? (
                    <>
                        <p>{t("rm_showUploadConfirmationDialog-text-meetingRooms-update")}</p>
                        {meetingRoomsToUpdate.map(meetingRoom => (
                            <p key={meetingRoom.name}><strong>{meetingRoom.name}</strong></p>
                        ))}
                    </>
                ) : null}
                {seatIdsToDelete.length > 0 ? (
                    <>
                        <p>{t("rm_showUploadConfirmationDialog-text-seats")}</p>
                        {seatIdsToDelete.map((seat) => (
                            <p key={seat.seatId}><strong>{seat.seatId}</strong></p>
                        ))}
                    </>
                ) : null}
                {seatsToUpdate.length > 0 ? (
                    <>
                        <p>{t("rm_showUploadConfirmationDialog-text-seats-update")}</p>
                        {seatsToUpdate.map((seat) => (
                            <p key={seat.seatId}><strong>{seat.seatId}</strong></p>
                        ))}
                    </>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => triggerUploadRoomPlan()}
                    color={"primary"}
                    variant={"contained"}
                    startIcon={onUploadLoading && <CircularProgress style={{color: "white"}} size={25}/>}
                >
                    {t("rm_showUploadConfirmationDialog_refresh_button-text")}
                </Button>
                <Button
                    onClick={onCloseUploadConfirmationDialog}
                    color={"primary"}
                    variant={"contained"}
                >
                    {t("rm_showUploadConfirmationDialog_cancel_button-text")}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default RoomManagerUploadConfirmationDialog;