import {gql, useQuery} from "@apollo/client";
import {getPresignedImageUrl, getPresignedRoomplanUrl} from "../graphql/queries";
import {ChangeEvent} from "react";

function s3FileIdGenerator() {
    return Math.random().toString(36).substring(2, 10);
}

export const useFileUpload = () => {
    const {refetch: refetchPreSignedImageUrl} = useQuery(gql(getPresignedImageUrl));
    const {refetch: refetchPreSignedSvgUrl} = useQuery(gql(getPresignedRoomplanUrl));


    const uploadImage = async (roomId: string, file: File) => {
        const imageId = s3FileIdGenerator()

        const preSignedUrlResponse = await refetchPreSignedImageUrl({
            writeAccess: true,
            roomId: roomId,
            imageId: imageId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedImageUrl) {
            return
        }

        const preSignedUrl = preSignedUrlResponse.data.getPresignedImageUrl
        await fetch(preSignedUrl, {
            method: "PUT",
            body: file
        }).catch((err) => {
            console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation")
            return undefined
        })
        return imageId
    }

    const getImageUrl = async (roomId: string, imageId: string) => {
        const preSignedUrlResponse = await refetchPreSignedImageUrl({
            writeAccess: false,
            roomId: roomId,
            imageId: imageId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedImageUrl) {
            return
        }

        return preSignedUrlResponse.data.getPresignedImageUrl
    }

    const uploadSvg = async (roomId: string, file: File | undefined) => {
        if (!file) {
            return undefined
        }

        const svgId = s3FileIdGenerator()

        const preSignedUrlResponse = await refetchPreSignedSvgUrl({
            writeAccess: true,
            roomId: roomId,
            roomplanId: svgId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedRoomplanUrl) {
            return
        }

        const preSignedUrl = preSignedUrlResponse.data.getPresignedRoomplanUrl
        await fetch(preSignedUrl, {
            method: "PUT",
            body: file
        }).catch((err) => {
            console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation")
            return
        })
        return svgId
    }

    const getSvgUrl = async (roomId: string, roomplanId: string) => {
        const preSignedUrlResponse = await refetchPreSignedSvgUrl({
            writeAccess: false,
            roomId: roomId,
            roomplanId: roomplanId
        }).catch((err) => console.log(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedRoomplanUrl) {
            return
        }

        return preSignedUrlResponse.data.getPresignedRoomplanUrl
    }

    const getFileContent = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            return event.target.files[0];
        }
        return undefined
    }

    return {
        uploadImage,
        getImageUrl,
        uploadSvg,
        getSvgUrl,
        getFileContent
    }
}